import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes ,Redirect} from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Logout = React.lazy(() => import('./views/pages/logout/Logout'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgot-password/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/reset-password/ResetPassword'))
const UserActivation = React.lazy(() => import('./views/pages/user-activation/UserActivation'))
const MembershipPage = React.lazy(() => import('./views/pages/membership/MembershipPage'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const isAuthenticated = () => {
	//write your condition here
	debugger
	var token=localStorage.getItem('token');
	if(token!=undefined || token!='')
		return true
	else
		return false;
}

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} render={props => <ForgotPassword {...props}/>}  />
            <Route exact path="/reset-password" name="Reset Password" element={<ResetPassword />} render={props => <ResetPassword {...props}/>}  />
            <Route exact path="/user-activation" name="User Activation" element={<UserActivation />} render={props => <UserActivation {...props}/>}  />
            <Route exact path="/membership-page" name="Membership Page" element={<MembershipPage />} render={props => <MembershipPage {...props}/>}  />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route exact path="/logout" name="Logout Page" element={<Logout />} render={props => <Logout {...props}/>} />
            <Route exact path="/login" name="Login Page" element={<Login />} render={props => <Login {...props}/>} />
    </Routes>
    {/* <Switch>
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/admin" name="Home" render={props => <DefaultLayout {...props}/>} />
              <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
            </Switch>*/}
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
